import * as THREE from "three";
import gsap from "gsap";
import hoverEffect from "hover-effect";

export default function initHoverEffect() {
  // if (!$(".shuffle").length) {
  //   return;
  // }

  Array.from(document.querySelectorAll(".project__image")).forEach((el) => {
    const imgs = Array.from(el.querySelectorAll("img"));

    new hoverEffect({
      parent: el,

      intensity: 0.1,
      image1: imgs[0].getAttribute("src"),
      image2: imgs[1].getAttribute("src"),
      displacementImage: el.dataset.displacement,
      imagesRatio: 1.25 // Set the aspect ratio to 4:5
    });

    // new hoverEffect({
    //   parent: el,
    //   intensity: el.dataset.intensity || undefined,
    //   speedIn: el.dataset.speedin || undefined,
    //   speedOut: el.dataset.speedout || undefined,
    //   easing: el.dataset.easing || undefined,
    //   hover: el.dataset.hover || undefined,
    //   image1: imgs[0].getAttribute("src"),
    //   image2: imgs[1].getAttribute("src"),
    //   displacementImage: el.dataset.displacement,
    // });
  });
}
