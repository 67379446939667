export default function initAppHeight() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();
  window.addEventListener("DOMContentLoaded", (event) => {
    appHeight();
  });
}
