// console.log("Hi From App JS");

import initAppHeight from "./scripts/appHeight";
import initHoverEffect from "./scripts/hoverEffect";
import initImageTrail from "./scripts/imageTrail";
// import initLoader from "./scripts/loader";
import initScrollMarque from "./scripts/scrollMarque";
import initSuffleEffect from "./scripts/shuffle";
import initSmoothScroll from "./scripts/smoothScroll";
// import initTimeZone from "./scripts/timeZone";
// import initVimeoPlayPauze from "./scripts/vimeoPlayer";
import initDropdown from "./scripts/dropdown";
import initScrambleText from "./scripts/scrambleText";

initAppHeight();
// initLoader();
initScrambleText();
initSmoothScroll();
initScrollMarque();
initDropdown();
initHoverEffect();
initSuffleEffect();
initImageTrail();
