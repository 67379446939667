import $ from "jquery";
import gsap from "gsap";
// import "./plugins/scrumbleTextPlugin";

// gsap.registerPlugin();

function initScrambleText() {
  //   if (!$("[scramble-link]").length) {
  //     return;
  //   }

  const links = document.querySelectorAll("[scramble-link]");

  links.forEach((link) => {
    let linkTl = gsap.timeline({
      paused: true,
    });

    // linkTl.to(link.querySelector("[scramble-text]"), {
    // //   scrambleText: {
    // //     chars: "061ZEROSIXONE",
    // //     text: "{original}",
    // //     speed: 2,
    // //     delimiter: "",
    // //   },
    //   duration: 0.8,
    // });

    link.addEventListener("mouseenter", () => {
      //   linkTl.timeScale(1).play();
      console.log($(this));
      $(this).addClass("hover-btn");
    });

    link.addEventListener("mouseleave", () => {
      //   linkTl.timeScale(1.5).reverse();
      $(this).removeClass("hover-btn");
    });
  });
}

export default initScrambleText;
